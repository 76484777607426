@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 16px;
  margin-right: 16px;

  height: 72px;

  background: white;

  border-top: 1px solid #E9ECEF;

  @media (--viewportMedium) {
    display: none;
  }
}

.filterIcon {
  height: 24px;
  width: 24px;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
}

.filtersButton {
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;
  height: 48px;
  padding: 0 18px;
}

.filtersButtonSelected {
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 48px 0;
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  /* border-bottom: 1px solid var(--matterColorNegative); */
}

.modalHeading {
  composes: h1 from global;
  margin-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}

.filterTopBar {
  position: relative;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.topBarBackArrow {
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
}

.topBarLabel {
  margin: 0;
  width: 100%;
}

.topBarResetAll {
  position: absolute;
  right: 0;
  color: var(--matterColorDark);
  font-size: 16px;
}

.openFiltersButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 164px;
  height: 40px;

  border: 1px solid #212529;
  border-radius: 8px;
}

.openFiltersButton:hover, .topBarBackArrow:hover, .topBarResetAll:hover {
  cursor: pointer;
}

.filterButtonIcon {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.filterButtonTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #343A40;

  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
