@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  background-color: white;
  filter: unset !important;

  @media (--viewportMedium) {
    display: none !important;
  }
}

.container {
  width: 100%;
  flex-grow: 1;
  display: flex;

  background: white;

  @media (--viewportMedium) {
    position: relative;
    min-height: calc(100dvh - var(--topbarHeightDesktop));
    display: flex;
    justify-content: center;
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllFiltersButton,
.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}
.resetAllFiltersButton {
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--matterColorLight);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--matterColorNegative);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    max-width: 1320px;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 16px 24px 0 16px;
    margin-bottom: 16px;

    position: sticky;
    top: var(--topbarHeightDesktopFirstRow);
    background: white;
    z-index: 10;
  }

  @media (--viewportLarge) {
    padding: 24px 24px 0 24px;
  }

  @media (min-width: 1360px) {
    padding: 24px 0 0 0;
  }
}

.searchFiltersMobileMap {
  border: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 16px;

  @media (--viewportLarge) {
    padding: 0 24px;
  }

  @media (min-width: 1360px) {
    padding: 0 0 0 24px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByDesktop {
  margin-left: auto;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100dvh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100dvh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.searchBarWrapper {
  padding: 4px 16px 8px 16px;
}

.searchBarRoot {
  margin-bottom: unset !important;
}

.searchPageEmptyStateWrapper {
  height: 100%;
}

.searchPageEmptyStateRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 60dvh;
}

.searchPageEmptyStateIcon {
  height: 42px;
  width: 42px;
}

.searchPageEmptyStateMessage {
  text-align: center;
  color: #495057;
  font-size: 16px;
  line-height: 21px;
}

.noResultsWrapper {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopWrapper {
  @media (--viewportMedium) {
    display: flex;
    align-items: flex-start;
    padding-left: 24px;
  }

  @media (min-width: 1360px) {
    padding-left: 0;
  }
}

.categorySelectionRoot {
  margin-bottom: 8px;

  background: white;

  display: flex;
  align-items: center;
  
  width: 100%;
  height: 44px;

  @media (--viewportMedium) {
    padding-left: 24px;
    margin-top: 24px;
    height: 54px;
  }

  @media (min-width: 1360px) {
    padding-left: 0;
  }
}

.categorySelectionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (--viewportMedium) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.categorySelectionContainer::-webkit-scrollbar {
  display: none;
}

.categorySelection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;

  border: 1px solid #495057;
  border-radius: 20px;
  margin-right: 8px;
}

.categorySelection:hover {
  cursor: pointer;
}

.categoryTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #495057;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loaderWrapper {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dividerSmall {
  height: 16px;
  width: 100%;
}

.topbarRoot {
  @media (--viewportMedium) {
    filter: drop-shadow(0px 4px 6px rgba(24, 39, 75, 0.06));
  }
}

.mobileTopbar {
  filter: drop-shadow(0px 4px 6px rgba(24, 39, 75, 0.06));
  background: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar {
  display: none;

  @media (--viewportMedium) {
    display: unset;
  }
}
